import { IPayrollHubACL } from "@acl/acl.interfaces";
import { commonFieldAcl } from "@acl/modules";

export const payrollHubAcl: IPayrollHubACL = {
  parent: { action: "payroll-hub", resource: "payroll-hub" },
  payrolls: {
    ...commonFieldAcl("periodic-payrolls"),
    findAllUnassignedEmployees: { action: "findAllUnassignedEmployees", resource: "periodic-payrolls" },
    findDisbursedPeriodicPayrollByEmployee: {
      action: "findDisbursedPeriodicPayrollByEmployee",
      resource: "periodic-payrolls",
    },
    findPeriodicPayrollById: { action: "findPeriodicPayrollById", resource: "periodic-payrolls" },

    payrollPolicies: {
      findOne: { action: "findOne", resource: "payroll-policies" },
      upsert: { action: "upsert", resource: "payroll-policies" },
    },
    disbursement: {
      ...commonFieldAcl("disbursement-groups"),
      disbursed: { action: "disbursed", resource: "disbursement-groups" },
      findDisbursementGroups: { action: "findDisbursementGroups", resource: "disbursement-groups" },
      createGroupWithEmployees: { action: "createGroupWithEmployees", resource: "disbursement-groups" },
      removeEmployees: { action: "removeEmployees", resource: "disbursement-groups" },
      findEmployeeByDisbursementGroup: { action: "findEmployeeByDisbursementGroup", resource: "disbursement-groups" },
      moveEmployees: { action: "moveEmployees", resource: "disbursement-groups" },
    },
  },
  payslips: {
    findAll: { action: "findAll", resource: "payslips" },
    findOne: { action: "findOne", resource: "payslips" },
  },
};
