import { ISystemSettings } from "@interfaces/system.interface";

export const isBrowser = typeof window !== "undefined";
export const isServer = typeof window == "undefined";

type LocalStorageKey = {
  accessToken: string;
  dailyOrganization: unknown; // Define proper type if known
  offlineImage: unknown; // Define proper type if known
  permissions: unknown; // Define proper type if known
  systemSettings: ISystemSettings;
  userData: unknown; // Define proper type if known
};

type SessionStorageKey = {
  expandSidebar: boolean;
};

interface IStorageService<K extends Record<string, any>> {
  set<T extends keyof K>(key: T, value: K[T]): void;
  get<T extends keyof K>(key: T): K[T] | null;
  delete<T extends keyof K>(key: T): void;
  clear(): void;
}

// Define ILocalStorageService using mapped types
type ILocalStorageService = IStorageService<LocalStorageKey>;
type ISessionStorageService = IStorageService<SessionStorageKey>;

// Implementation of LocalStorageService
export const LocalStorageService: ILocalStorageService = {
  set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  get: (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  },
  delete: (key) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};

// Implementation of SessionStorageService
export const SessionStorageService: ISessionStorageService = {
  set: (key, value) => sessionStorage.setItem(key, JSON.stringify(value)),
  get: (key) => {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  },
  delete: (key) => sessionStorage.removeItem(key),
  clear: () => sessionStorage.clear(),
};

// Interface for systemSettings (replace with full structure)
