import Image from "@components/Image/Image";
import StatusSelect from "@components/Select/StatusSelect";
import { IEmployeeCard } from "@interfaces/employee.interface";
import { displayValue, nameMapper, trimString } from "utils";
import { Constant } from "utils/constant";
import { useProfileAvatar } from "utils/hook.utils";

type EmployeeCardProps = {
  index?: any;
  noPadding?: boolean;
  selected?: boolean;
  isDesignationTrim?: boolean;
  employeeData?: IEmployeeCard;
  onClick?: () => void;
  className?: string;
  trimCount?: number;
  nameClassName?: string;
  singleName?: boolean;
  avatarKey?: string | number;
  hasStatus?: boolean;
  status?: "active" | "warning" | "disconnect" | "current" | null;
};

const EmployeeCard = ({
  selected = false,
  noPadding = false,
  onClick,
  employeeData,
  className,
  // index = Math.floor(Math.random() * 100) + 1,
  index,
  trimCount = 18,
  nameClassName,
  isDesignationTrim,
  singleName = false,
  avatarKey,
  hasStatus,
  status,
}: EmployeeCardProps) => {
  // to maintain random avatar with all profile sequence

  const { path, avatarClass } = useProfileAvatar(employeeData, index, avatarKey);

  const designation = employeeData?.designation ? employeeData?.designation : employeeData?.designation_name;

  return (
    <div
      onClick={onClick && onClick}
      className={`z-10 ${selected && "bg-primary-50 sticky top-0 bottom-0 z-20"} ${
        !noPadding ? " py-3 px-6" : ""
      } flex items-center rounded-[6px] ${className ? className : ""}`}
    >
      <div className="relative">
        {<Image noBorder src={path} className={`w-10 h-10  rounded overflow-hidden ${avatarClass}`} />}

        {status && (
          <div
            className={`absolute -right-1 -bottom-1 w-[10px] h-[10px] rounded-full ${
              status === "active"
                ? "bg-success-500"
                : status === "warning"
                ? "bg-warning-500"
                : status === "current"
                ? "bg-danger-500"
                : status === "disconnect"
                ? "bg-neutral-500"
                : ""
            }`}
          ></div>
        )}
      </div>
      <div className={`flex justify-between items-start gap-3 ${nameClassName ? nameClassName : ""} pl-3`}>
        <div className="space-y-[4px]">
          <h4 className="m-0 p-0 leading-none font-inter text-sm font-semibold text-left text-dark-600">
            {singleName
              ? employeeData?.first_name || employeeData?.last_name || Constant.NO_DATA
              : employeeData?.full_name ||
                (employeeData?.first_name || employeeData?.last_name
                  ? trimString(nameMapper(employeeData), trimCount)
                  : Constant.NO_DATA)}
          </h4>
          <h5 className="m-0 p-0 leading-none font-inter text-[10px] text-left font-normal text-neutral-400">
            {isDesignationTrim && designation ? trimString(designation, 15) : designation || Constant.NO_DATA}
          </h5>
          <h5 className="font-inter leading-none text-[10px] font-normal text-left text-neutral-400">
            {displayValue(employeeData?.employee_no)}
          </h5>
        </div>
        {hasStatus && <StatusSelect showOnly value={employeeData?.status} />}
      </div>
    </div>
  );
};

export default EmployeeCard;
