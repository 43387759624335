import Icon from "@components/Icon/Icon";
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from "@components/Popover/Popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "@components/Tooltip/Tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
interface IStatusCOmponentProps {
  setValue?: (value: string) => void;
  options?: IOptions[];
  value?: string;
  disable?: boolean;
  disabledOpt?: string | any;
  showOnly?: boolean;
  hideDotSign?: boolean;
  hasPermitted?: boolean;
  dataTestId?: string;
}
interface IOptions {
  label: string;
  value: string;
}

export const getStatusStyle = (status: string) => {
  switch (status?.toLowerCase()) {
    case "available":
    case "present":
      return {
        background: "bg-success-50",
        text: "text-success-700",
        dotBackground: "bg-success-700",
        border: "border border-success-100 rounded-md shadow-sm",
      };
    case "allocation":
    case "repair":
    case "pending":
      return {
        background: "bg-warning-50",
        text: "text-warning-700",
        dotBackground: "bg-warning-700",
        border: "border border-warning-100 rounded-md shadow-sm",
      };
    case "absent":
      return {
        background: "bg-danger-50",
        text: "text-danger-700",
        dotBackground: "bg-danger-700",
        border: "border border-danger-100 rounded-md shadow-sm",
      };
    case "late":
      return {
        background: "bg-warning-50",
        text: "text-warning-500",
        dotBackground: "bg-warning-500",
        border: "border border-warning-100 rounded-md shadow-sm",
      };
    case "archived":
      return {
        background: "bg-primary-50",
        text: "text-primary-300",
        dotBackground: "bg-primary-300",
        border: "border border-primary-100 rounded-md shadow-sm",
      };
    case "approved":
    case "current":
    case "active":
      return {
        background: "bg-success-50",
        text: "text-success-600",
        dotBackground: "bg-success-600",
        border: "border border-success-100 rounded-md shadow-sm",
      };
    case "reject":
    case "showcause":
    case "suspended":
    case "damage":
    case "warning":
    case "termination":
      return {
        background: "bg-danger-50",
        text: "text-danger-500",
        dotBackground: "bg-danger-500",
        border: "border border-danger-100 rounded-md shadow-sm",
      };
    case "hold":
    case "leave":
      return {
        background: "bg-secondary-50",
        text: "text-secondary-500",
        dotBackground: "bg-secondary-500",
        border: "border border-secondary-100 rounded-md shadow-sm",
      };
    case "holiday":
      return {
        background: "bg-warning-50",
        text: "text-warning-500",
        dotBackground: "bg-warning-500",
        border: "border border-warning-100 rounded-md shadow-sm",
      };
    case "weekend":
      return {
        background: "bg-neutral-50",
        text: "text-neutral-500",
        dotBackground: "bg-neutral-400",
        border: "border border-neutral-100 rounded-md shadow-sm",
      };
    case "inactive":
    case "closed":
    case "N/A":
    case "n/a":
      return {
        background: "bg-neutral-50",
        text: "text-neutral-400",
        dotBackground: "bg-neutral-400",
        border: "border border-neutral-100 rounded-md shadow-sm",
      };
    default:
      return {
        background: "",
        text: "",
        dotBackground: "",
      };
  }
};

const StatusSelect = ({
  setValue,
  options,
  value,
  disabledOpt = "None",
  hideDotSign,
  showOnly = false,
  hasPermitted = true,
  dataTestId = "status-select-popover",
  disable = false,
}: IStatusCOmponentProps) => {
  const [statusValue, setStatusValue] = useState(value || "");
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleChange = (val: string) => {
    setValue && setValue(val);
    setStatusValue(val);
  };
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <>
      <Popover dataTestId={dataTestId} open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <PopoverTrigger className={`${showOnly ? "!cursor-default" : ""}`}>
          {/* <div
                onClick={() => {
                  hasPermitted && !disable && toggleDropdown();
                }}
                className={`${value ? getStatusStyle(value).background : ""} ${
                  value ? getStatusStyle(value)?.border : ""
                }
             ${
               showOnly ? "w-auto p-1.5 pointer-events-none cursor-default" : "w-[155px] px-3 py-1"
             } flex gap-5 items-center  relative  rounded ${
                  hasPermitted && !disable ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              >
                <div
                  className={`${value ? getStatusStyle(value).dotBackground : ""} w-2.5 h-2.5 rounded ${
                    hideDotSign ? "hidden" : "block"
                  }`}
                />
                {!showOnly && (
                  <div
                    className={`${
                      value ? getStatusStyle(value).text : ""
                    } font-inter text-sm font-medium flex items-center gap-5`}
                  >
                    {t((value && value?.toLowerCase()) || "")}

                    {hasPermitted && (
                      <Icon
                        name="expand_more"
                        variant="outlined"
                        className={`${dropdownOpen ? "rotate-180" : ""} absolute right-2 `}
                      />
                    )}
                  </div>
                )}
              </div> */}
          {showOnly ? (
            <>
              <Tooltip arrowColor={"#184066"}>
                <TooltipTrigger>
                  <div
                    className={`w-auto p-1.5 ${value ? getStatusStyle(value).background : ""} ${
                      value ? getStatusStyle(value)?.border : ""
                    } pointer-events-none cursor-default flex gap-5 items-center relative rounded`}
                  >
                    <div
                      className={`${value ? getStatusStyle(value).dotBackground : ""} w-2.5 h-2.5 rounded ${
                        hideDotSign ? "hidden" : "block"
                      }`}
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="bg-primary-900 px-3 rounded py-[6px] text-white w-[99px]">
                    <h2 className="text-xs font-medium text-center whitespace-nowrap">
                      {t((value && value?.toLowerCase()) || "")}
                    </h2>
                  </div>
                </TooltipContent>
              </Tooltip>
            </>
          ) : (
            <>
              <div
                onClick={() => {
                  hasPermitted && !disable && toggleDropdown();
                }}
                className={`${value ? getStatusStyle(value).background : ""} ${
                  value ? getStatusStyle(value)?.border : ""
                } w-[155px] px-3 py-1 flex gap-5 items-center relative rounded ${
                  hasPermitted && !disable ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              >
                <div
                  className={`${value ? getStatusStyle(value).dotBackground : ""} w-2.5 h-2.5 rounded ${
                    hideDotSign ? "hidden" : "block"
                  }`}
                />
                <div
                  className={`${
                    value ? getStatusStyle(value).text : ""
                  } font-inter text-sm font-medium flex items-center gap-5`}
                >
                  {t((value && value?.toLowerCase()) || "")}
                  {hasPermitted && (
                    <Icon
                      name="expand_more"
                      variant="outlined"
                      className={`${dropdownOpen ? "rotate-180" : ""} absolute right-2`}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </PopoverTrigger>
        <PopoverContent className="Popover">
          <PopoverBody>
            <div className="min-w-[155px] w-[155px] ">
              {!showOnly && (
                <div className="mt-1 w-full overflow-y-scroll no-scrollbar rounded-lg bg-transparent border border-gray-200 shadow-xl ">
                  {options?.map((opt: IOptions) => (
                    <div
                      key={opt?.value}
                      className={`${getStatusStyle(opt.value).background} flex items-center gap-2 px-3 py-2 ${
                        opt?.value.toLowerCase() === disabledOpt?.toLowerCase()
                          ? "cursor-not-allowed "
                          : "cursor-pointer"
                      }`}
                      onClick={() => {
                        opt?.value.toLowerCase() !== disabledOpt?.toLowerCase() && handleChange(opt.value),
                          setDropdownOpen(false);
                      }}
                    >
                      <span className={`${getStatusStyle(opt.value).text} font-inter text-sm font-medium`}>
                        {t(opt.label)}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default StatusSelect;
