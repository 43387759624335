import { ENV } from "@config/ENV.config";
import currenciesList from "@data/currency-details.json";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { isObject, isValidDate, nameMapper } from "utils";

import {
  attAdjustmentsCreatePolicy,
  currencySeparatorOptions,
  fileSizeOptions,
  timeFormatOptions,
  weekStartDayOptions,
} from "@data/common.data";
import { Constant } from "./constant";
import { generateDateFormat } from "./date.utils";

const defaultSystemSettings = {
  country_phone_code: "+880",
  token_lifetime: 10,
  timezone: {
    name: "Asia/Dhaka",
    value: "Asia/Dhaka",
  },
  date_format: {
    name: "mm/dd/yyyy",
    value: "mm/dd/yyyy",
  },
  time_format: { value: "12 hr", name: "12-hour" },
  currency_type: {
    name: "BDT",
    value: "BDT",
  },
  currency_precision: {
    name: 1,
    value: 1,
  },
  currency_symbol_placement: {
    name: "Before",
    value: "Before",
  },
  currency_adjustment_method: { name: "Actual", value: null },
  currency_separator: { value: ",", label: "Comma (,)", meta: "COMMA" },
  currency_counting: { value: "crores", label: "Lakh (1,00,000)", meta: "CRORES" },
  language: {
    name: "en",
    value: "en",
  },
  notification: {
    birthday: true,
    new_joining: false,
  },
  file_types: [
    {
      value: "pdf",
      name: "pdf",
    },
  ],
  file_size: {
    value: "1.3",
  },
  image_types: [
    {
      value: "jpeg",
      name: "jpeg",
    },
    {
      value: "png",
      name: "png",
    },
    {
      value: "jpg",
      name: "jpg",
    },
  ],
  image_size: {
    value: "3.2",
  },
  // TODO :: temporary used data limit
  data_limit: {
    id: "1",
    name: "limit",
    value: "10",
    label: "10_items",
  },
};

// IIFE used to store default system setting in local storage if it's not available
(() => {
  const localData = LocalStorageService.get("systemSettings");
  if (!localData) {
    LocalStorageService.set("systemSettings", defaultSystemSettings);
    window.dispatchEvent(new Event("storage"));
  }
})();

export const systemDate = (date: any) => {
  try {
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { date_format } = systemSettingsOptions;

    if (date && !isValidDate(date)) date = new Date(date);
    if (!isValidDate(date)) return Constant.NO_DATA;
    return generateDateFormat(date, isObject(date_format) ? date_format?.value : date_format);
  } catch (error) {
    ENV.env === "development" && console.error(error);
    return Constant.NO_DATA;
  }
};

// export const systemTime = (time: any) => {
//   try {
//     if (!time) return Constant.NO_DATA;
//     const systemSettingsOptions = LocalStorageService.get("systemSettings");
//     const { timeZone, time_format } = systemSettingsOptions;
//     const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;
//     const providedTime = new Date(time);
//     const now = new Date();
//     let formattedTime: string = "";
//     const givenTimeParts = time.split(":");

//     console.log(providedTime);

//     if (!isValidDate(providedTime)) {
//       // Set time components to the given time
//       now.setHours(parseInt(givenTimeParts[0], 10));
//       now.setMinutes(parseInt(givenTimeParts[1], 10));
//       now.setSeconds(parseInt(givenTimeParts[2], 10));
//       console.log(now);

//       // Format the time in the desired format
//       formattedTime = now.toLocaleTimeString("en-US", {
//         hour12: hour12,
//         hour: "numeric",
//         minute: "numeric",
//         timeZone: isObject(timeZone) ? timeZone.value : timeZone,
//       });
//     } else {
//       formattedTime = providedTime.toLocaleTimeString("en-US", {
//         hour12: hour12,
//         hour: "numeric",
//         minute: "numeric",
//         timeZone: isObject(timeZone) ? timeZone.value : timeZone,
//       });
//     }

//     return formattedTime;
//   } catch (error) {
//     ENV.env === "development" && console.error(error);
//     return null;
//   }
// };

export const systemTime = (time: any): string | null => {
  try {
    if (!time) return Constant.NO_DATA;

    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { timeZone, time_format } = systemSettingsOptions;
    const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;

    let providedTime: Date;
    let formattedTime: string = "";

    // Check if time is a timestamp
    if (typeof time === "number" || !isNaN(Date.parse(time))) {
      providedTime = new Date(time);
    }
    // Handle HH:MM:SS format
    else if (typeof time === "string" && /^\d{1,2}:\d{2}(:\d{2})?$/.test(time)) {
      const now = new Date();
      const givenTimeParts = time.split(":");
      now.setHours(parseInt(givenTimeParts[0], 10));
      now.setMinutes(parseInt(givenTimeParts[1], 10));
      now.setSeconds(parseInt(givenTimeParts[2] || "0", 10));
      providedTime = now;
    } else {
      throw new Error("Invalid time format");
    }

    // Validate the date object
    if (!isValidDate(providedTime)) {
      throw new Error("Invalid date object");
    }

    // Format the time
    formattedTime = providedTime.toLocaleTimeString("en-US", {
      hour12: hour12,
      hour: "numeric",
      minute: "numeric",
      timeZone: isObject(timeZone) ? timeZone.value : timeZone,
    });

    return formattedTime;
  } catch (error) {
    if (ENV.env === "development") console.error(error);
    return null;
  }
};

// TODO:: systemTime and systemTimeWithSecond is similar utils later both will be merged
export const systemTimeWithSecond = (time: any): string | null => {
  try {
    // Return a constant if time is not provided
    if (!time) return Constant.NO_DATA;

    // Get system settings from local storage
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { timeZone, time_format } = systemSettingsOptions;

    // Determine whether to use 12-hour or 24-hour format
    const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;

    let providedTime: Date;

    // Check if the input is a timestamp or a valid date string
    if (typeof time === "number" || !isNaN(Date.parse(time))) {
      providedTime = new Date(time);
    }
    // Handle HH:MM:SS or HH:MM format
    else if (typeof time === "string" && /^\d{1,2}:\d{2}(:\d{2})?$/.test(time)) {
      const now = new Date();
      const [hours, minutes, seconds] = time.split(":").map((part) => parseInt(part, 10));
      now.setHours(hours, minutes, seconds || 0, 0);
      providedTime = now;
    } else {
      throw new Error("Invalid time format");
    }

    // Validate the Date object
    if (!(providedTime instanceof Date) || isNaN(providedTime.getTime())) {
      throw new Error("Invalid date object");
    }

    // Format the time with seconds
    const formattedTime = providedTime.toLocaleTimeString("en-US", {
      hour12: hour12,
      hour: "numeric",
      minute: "numeric",
      second: "numeric", // Ensure seconds are included
      timeZone: isObject(timeZone) ? timeZone.value : timeZone,
    });

    return formattedTime;
  } catch (error) {
    // Log error in development mode
    if (ENV.env === "development") console.error(error);
    return null;
  }
};

export const systemDateTime = (timeStamp: any) => {
  if (!timeStamp) return;
  return systemDate(timeStamp) + ", " + systemTime(timeStamp);
};

// export const systemCurrency = (amount: string | number, symbol: boolean = false, onlyAmount: boolean = false) => {
//   try {
//     if (!amount) return Constant.NO_DATA;

//     amount = systemCurrencyPrecision(Number(amount));
//     const {
//       currency_type: { value: currency = "BDT" },
//       currency_symbol_placement: { value: placement = "After" },
//       currency_separator: { value: separator = "" } = {},
//       currency_counting: { value: counting = "" } = {},
//     } = LocalStorageService.get("systemSettings");

//     const { symbol_native: currency_symbol = "৳" } = currenciesList[currency];

//     if (counting && separator) {
//       // amount with separator
//       amount = systemCurrencySeparator(Number(amount), separator, counting);
//     }

//     if (!onlyAmount) {
//       if (placement == "Before") {
//         if (symbol) {
//           amount = currency_symbol + " " + amount || "";
//         } else {
//           amount = currency + " " + amount || "";
//         }
//       } else if (placement == "After") {
//         if (symbol) {
//           amount = amount + " " + currency_symbol || "";
//         } else {
//           amount = amount + " " + currency || "";
//         }
//       }
//     }

//     return amount;
//   } catch (error) {
//     ENV.env === "development" && console.error("Error:", error);
//     return Constant.NO_DATA;
//   }
// };

interface ISystemCurrency {
  amount: string | number;
  symbol?: boolean;
  onlyAmount?: boolean;
  notFound?: string;
  config?: Config;
}

type Config = { precision?: boolean; separator?: boolean; currency?: string };

export const systemCurrency = (
  argAmount: string | number | ISystemCurrency,
  argSymbol: boolean = false,
  argOnlyAmount: boolean = true,
  argNotFound?: string,
  argConfig?: Config
) => {
  let amount, symbol, onlyAmount, notFound, config;

  if (typeof argAmount === "object" && isObject(argAmount)) {
    // Destructure the properties from the object argument
    ({ amount, symbol, onlyAmount, notFound, config } = argAmount);
  } else if (typeof argAmount === "string" || typeof argAmount === "number") {
    // Assign the individual arguments directly
    amount = argAmount;
    symbol = argSymbol;
    onlyAmount = argOnlyAmount;
    notFound = argNotFound;
    config = argConfig;
  }

  try {
    if (!amount && isNaN(Number(amount))) return notFound != undefined ? notFound : Constant.NO_DATA;

    // TODO:: temporary turned off

    if (config?.precision) {
      amount = systemCurrencyPrecision(Number(amount));
    }

    const {
      currency_type: { value: currency = "BDT" },
      currency_symbol_placement: { value: placement = "After" },
      currency_separator: { value: separator = "" } = {},
      currency_counting: { value: counting = "" } = {},
    } = LocalStorageService.get("systemSettings");

    const { symbol_native: currency_symbol = "৳" } = currenciesList[currency];

    if (separator && config?.separator) {
      // amount with separator
      amount = systemCurrencySeparator(amount, separator, currency == "BDT" ? "crores" : "millions");
    }

    if (!onlyAmount) {
      if (placement == "Before") {
        if (symbol) {
          amount = `${currency_symbol} ${amount || ""}`;
        } else {
          amount = `${config?.currency || currency} ${amount || ""}`;
        }
      } else if (placement == "After") {
        if (symbol) {
          amount = `${amount} ${currency_symbol || ""}`;
        } else {
          amount = `${amount} ${config?.currency || currency || ""}`;
        }
      }
    }

    return amount;
  } catch (error) {
    ENV.env === "development" && console.error("Error:", error);
    return Constant.NO_DATA;
  }
};

export const systemCurrencyParser = (
  input: string | Record<string, any> | Record<string, any>[],
  keysToParse?: string[],
  newProps: boolean = false
): number | Record<string, any> | Record<string, any>[] | null => {
  // Helper function to clean and parse number strings
  const cleanNumber = (value: string): number | null => {
    if (!value) return null;
    const normalized = value.replace(/[\s',]/g, "");
    const num = Number(normalized);
    return isNaN(num) ? null : num;
  };

  // If input is a string, parse normally
  if (typeof input === "string") {
    return cleanNumber(input);
  }

  // If input is an array, process each object inside it
  if (Array.isArray(input)) {
    return input.map((item) => (typeof item === "object" ? systemCurrencyParser(item, keysToParse, newProps) : item));
  }

  // If input is an object and keysToParse is provided, modify the object
  if (typeof input === "object" && keysToParse?.length) {
    const parsedObject = { ...input };

    for (const key of keysToParse) {
      if (parsedObject[key] && typeof parsedObject[key] === "string") {
        const parsedValue = cleanNumber(parsedObject[key]);

        if (newProps) {
          parsedObject[`parsed_${key}`] = parsedValue; // Store in new property
        } else {
          parsedObject[key] = parsedValue; // Update existing property
        }
      }
    }

    return parsedObject;
  }

  return null;
};

// export const systemCurrencyPrecision = (amount: number) => {
//   try {
//     if (isNaN(amount)) return Constant.NO_DATA;

//     const {
//       currency_precision: { value: precision = "2" },
//     } = LocalStorageService.get("systemSettings");

//     return amount.toFixed(precision);
//   } catch (error) {
//     ENV.env === "development" && console.error("Error:", error);
//     return Constant.NO_DATA;
//   }
// };

export const systemCurrencyPrecision = (amount: number) => {
  try {
    if (isNaN(amount)) return Constant.NO_DATA;

    const {
      currency_precision: { value: precision = Constant.SYSTEM_SETTINGS_DEFAULT.CURRENCY_PRECISION },
    } = LocalStorageService.get("systemSettings");

    const precisionValue = parseInt(precision, 10);

    // Convert the number to a string and split into integer and fractional parts
    const [integerPart, fractionalPart = ""] = amount.toString().split(".");

    // Truncate the fractional part to the specified precision
    const truncatedFraction = fractionalPart.slice(0, precisionValue).padEnd(precisionValue, "0");

    return `${integerPart}.${truncatedFraction}`;
  } catch (error) {
    if (ENV.env === "development") {
      console.error("Error:", error);
    }
    return Constant.NO_DATA;
  }
};

export const systemCurrencySeparator = (
  amount: number | string,
  separator: string,
  countingStyle: "crores" | "millions"
) => {
  try {
    // if (isNaN(Number(amount))) throw new Error("Amount must be a number");

    const isNegative = Number(amount) < 0;

    // Convert to a string and split into integer and fractional parts
    const [integerPartRaw, fractionalPart = ""] = String(amount).split(".");

    let integerPart = isNegative ? integerPartRaw.split("-").pop() : integerPartRaw;

    if (countingStyle === "crores") {
      const lastThreeDigits = integerPart.slice(-3); // Last 3 digits (hundreds)

      let middleDigits, restOfTheNumber;

      if (integerPart.length === 6) {
        middleDigits = integerPart.slice(-5, -3); // Thousands and lacs
        restOfTheNumber = integerPart.slice(0, -5);
      } else {
        middleDigits = integerPart.slice(-7, -3); // Thousands and lacs
        restOfTheNumber = integerPart.slice(0, -7); // Crores
      }

      // Format middle digits (thousands and lacs)
      const formattedMiddle = middleDigits ? middleDigits.replace(/(\d{2})(?=\d)/g, `$1${separator}`) : "";

      // Combine all parts
      integerPart = [
        restOfTheNumber, // Crores
        formattedMiddle, // Thousands and lacs
        lastThreeDigits, // Hundreds
      ]
        .filter(Boolean)
        .join(separator); // Join with the separator
    } else if (countingStyle === "millions") {
      // Western format (group by 3 digits)
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    } else {
      throw new Error("Invalid counting style. Use 'millions' or 'crores'.");
    }

    // Ensure the fractional part is appended correctly
    const returnAmount = fractionalPart
      ? `${integerPart}.${fractionalPart.padEnd(fractionalPart.length, "0")}`
      : integerPart;

    return (isNegative ? "-" : "") + returnAmount;
  } catch (error) {
    console.error("Error in systemCurrencySeparator:", error);
    return "Invalid amount";
  }
};

export const systemSettingsMapper = (payload: any) => {
  try {
    const {
      contact_person,
      image_size,
      time_format,
      file_size,
      date_format,
      timezone,
      language,
      currency_type,
      currency_precision,
      currency_separator,
      currency_counting,
      currency_symbol_placement,
      currency_adjustment_method,
      week_start_day,
      file_types = [],
      image_types = [],
      att_adjustment_create_policy,

      // attendance_types = [],
    } = payload;

    // Utility function to map types
    const mapTypes = (types: any[]) => (Array.isArray(types) ? types.map((item) => ({ value: item, name: item })) : []);

    // Utility function to find options
    const findOption = (options: any[], value: any) => options?.find((item) => item?.value === value);

    // Map fields
    const mappedPayload = {
      ...payload,
      contact_person: contact_person ? { ...contact_person, name: nameMapper(contact_person) } : undefined,
      country_phone_code: "+880",
      language: isObject(language)
        ? language
        : {
            name: language === "ar" ? "عربي" : language === "en" ? "English" : language === "bn" ? "বাংলা" : language,
            value: language,
          },
      week_start_day: week_start_day > -1 ? findOption(weekStartDayOptions, week_start_day) : undefined,
      image_size: findOption(fileSizeOptions, image_size),
      file_size: findOption(fileSizeOptions, file_size),
      att_adjustment_create_policy: findOption(attAdjustmentsCreatePolicy, att_adjustment_create_policy),
      timezone: timezone ? { name: timezone, value: timezone } : undefined,
      date_format: date_format ? { name: date_format, value: date_format } : undefined,
      time_format: findOption(timeFormatOptions, time_format),
      currency_type: currency_type ? { name: currency_type, value: currency_type } : undefined,
      currency_precision: currency_precision ? { name: currency_precision, value: currency_precision } : undefined,
      currency_symbol_placement: currency_symbol_placement
        ? { name: currency_symbol_placement, value: currency_symbol_placement }
        : undefined,
      currency_adjustment_method: currency_adjustment_method
        ? { name: currency_adjustment_method, value: currency_adjustment_method }
        : undefined,
      currency_separator: currencySeparatorOptions?.find((item) => item?.meta === currency_separator),
      // currency_counting: currencyCountingOptions?.find((item) => item?.meta === currency_counting),
      file_types: mapTypes(file_types),
      image_types: mapTypes(image_types),
      // attendance_types: mapTypes(attendance_types),
    };

    return mappedPayload;
  } catch (error) {
    console.error("Error in systemSettingsMapper:", error);
    return {}; // Return an empty object or handle as needed
  }
};

export const systemSettingsRequestData = (data: any) => {
  data.file_types = data?.file_types?.map((item: any) => item.value);

  data.image_types = data.image_types?.map((item: any) => item.value);

  data.timezone = data.timezone?.value;

  data.time_format = data.time_format?.value;
  data.date_format = data.date_format?.value;
  data.language = data.language?.value;
  data.image_size = data.image_size?.value;
  data.file_size = data.file_size?.value;
  data.currency_precision = data.currency_precision?.value;
  data.currency_symbol_placement = data.currency_symbol_placement?.value;
  data.currency_adjustment_method = data.currency_adjustment_method?.value;
  data.currency_type = data.currency_type?.value;

  return data;
};

export const systemSettings = () => {
  return LocalStorageService.get("systemSettings") || defaultSystemSettings;
};

export const convertShiftRuleCalculationTime = (time: any) => {
  try {
    if (!time) return Constant.NO_DATA;
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { timeZone, time_format } = systemSettingsOptions;
    const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;

    const timeParts = time.split(" ");
    const [hours, minutes, seconds] = timeParts[0].split(":").map(Number);
    const ampm = timeParts[1]?.toUpperCase();

    const providedDate = new Date("1970-01-01T00:00:00");
    providedDate.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, seconds || 0);

    const formatter = new Intl.DateTimeFormat("en-US", {
      hour12: hour12,
      hour: "numeric",
      minute: "numeric",
      timeZone: isObject(timeZone) ? timeZone.value : timeZone,
    });

    return formatter.format(providedDate);
  } catch (error) {
    ENV.env === "development" && console.error(error);
    return null;
  }
};
