import { reportAcl } from "@acl/modules/report.data";
import { lazy } from "react";
import {
  ATTENDANCE_ACTIVITY_LOG_REPORTS,
  ATTENDANCE_LATE_EARLY,
  CHANNELS,
  INDIVIDUAL,
  PAYROLLS,
  PAYSLIPS,
  PREVIEW,
  SALARY_SHEET,
  SUMMARY,
} from "router/path-name.route";
import { IRouteList } from "./list.route";
import {
  ADJUSTMENTS,
  ALL_SUMMARY,
  APPLICATION_STATUS,
  ATTENDANCE_REPORT,
  BALANCE_REPORT,
  EMPLOYEE_COST,
  EMPLOYEE_LOG,
  EMPLOYEE_SUMMARY,
  INDIVIDUAL_ATTENDANCE_REPORTS,
  INDIVIDUAL_SUMMARY,
  LEAVE,
  PAYMENT,
  PAYROLL,
  PAYROLL_ATTENDANCE,
  PAYROLL_LEAVE,
  REPORTS,
  SCHEDULES_OVERVIEW,
  STATISTICS,
} from "./path-name.route";

export const reportRoutes: IRouteList[] = [
  {
    path: REPORTS,
    component: lazy(() => import("@pages/Reports/Reports")),
    meta: {
      action: reportAcl.parent.action,
      resource: reportAcl.parent.resource,
    },
  },

  {
    path: REPORTS + ALL_SUMMARY,
    component: lazy(() => import("@pages/Reports/AllReports/Employee/EmployeeAllSummary/EmployeeAllSummary")),
    meta: {
      action: reportAcl.employees.allSummary.action,
      resource: reportAcl.employees.allSummary.resource,
    },
  },
  {
    path: REPORTS + INDIVIDUAL_SUMMARY,
    component: lazy(
      () => import("@pages/Reports/AllReports/Employee/EmployeeIndividualSummary/EmployeeIndividualSummary")
    ),
    meta: {
      action: reportAcl.employees.individualDetails.action,
      resource: reportAcl.employees.individualDetails.resource,
    },
  },
  {
    path: REPORTS + ATTENDANCE_REPORT + "/*",
    component: lazy(() => import("@pages/Reports/AllReports/Attendance/AttendanceAllEmployees/AttendanceAllEmployees")),
    meta: {
      action: reportAcl.attendances.allEmployee.action,
      resource: reportAcl.attendances.allEmployee.resource,
    },
    children: [
      {
        path: SUMMARY,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Attendance/AttendanceAllEmployees/Tab/AttendanceAllEmploySummaryReport/AttendanceAllEmploySummary"
            )
        ),
        meta: {
          action: reportAcl.attendances.allEmployee.action,
          resource: reportAcl.attendances.allEmployee.resource,
        },
      },
      {
        path: STATISTICS,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Attendance/AttendanceAllEmployees/Tab/AttendanceStatistics/AttendanceStatistics"
            )
        ),
        meta: {
          action: reportAcl.attendances.allEmployee.action,
          resource: reportAcl.attendances.allEmployee.resource,
        },
      },
    ],
  },
  {
    path: REPORTS + ATTENDANCE_LATE_EARLY + "/*",
    component: lazy(() => import("@pages/Reports/AllReports/Attendance/AttendanceLateEarly/AttendanceLateEarly")),
    meta: reportAcl.attendances.getLateEarlyAttendances,
  },

  {
    path: REPORTS + INDIVIDUAL_ATTENDANCE_REPORTS,
    component: lazy(
      () => import("@pages/Reports/AllReports/Attendance/IndividualAttendance/IndividualAttendanceReport")
    ),
    meta: {
      action: reportAcl.attendances.individualEmployee.action,
      resource: reportAcl.attendances.individualEmployee.resource,
    },
  },
  {
    path: REPORTS + ATTENDANCE_ACTIVITY_LOG_REPORTS,
    component: lazy(() => import("@pages/Reports/AllReports/Attendance/ActivityLog/ActivityLogReport")),
    meta: {
      // action: reportAcl.attendances.activityLog.action,
      // resource: reportAcl.attendances.activityLog.resource,
      action: reportAcl.attendances.individualEmployee.action,
      resource: reportAcl.attendances.individualEmployee.resource,
    },
  },
  {
    path: REPORTS + EMPLOYEE_LOG,
    component: lazy(() => import("@pages/Reports/AllReports/Attendance/AttendanceLog/AttendanceLogReport")),
    meta: {
      action: reportAcl.attendances.dailySummary.action,
      resource: reportAcl.attendances.dailySummary.resource,
    },
  },
  {
    path: REPORTS + EMPLOYEE_SUMMARY,
    component: lazy(() => import("@pages/Reports/AllReports/Attendance/AttendanceSummary/AttendanceSummaryReport")),
    meta: {
      action: reportAcl.attendances.allSummary.action,
      resource: reportAcl.attendances.allSummary.resource,
    },
  },

  // --------leave route

  {
    path: REPORTS + LEAVE + APPLICATION_STATUS + "/*",
    component: lazy(
      () => import("@pages/Reports/AllReports/Leave/LeaveApplicationStatus/LeaveApplicationStatusReport")
    ),
    meta: {
      action: reportAcl.leaves.applicationStatus.action,
      resource: reportAcl.leaves.applicationStatus.resource,
    },
    children: [
      {
        path: SUMMARY,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Leave/LeaveApplicationStatus/Tab/ApplicationStatusSummary/ApplicationStatusSummaryReport"
            )
        ),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
      {
        path: STATISTICS,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Leave/LeaveApplicationStatus/Tab/LeaveStatusStatistics/LeaveStatusStatictics"
            )
        ),
        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
    ],
  },

  {
    path: REPORTS + LEAVE + BALANCE_REPORT + "/*",
    component: lazy(() => import("@pages/Reports/AllReports/Leave/LeaveBalanceReport/LeaveBalanceReport")),
    meta: {
      action: reportAcl.leaves.balance.action,
      resource: reportAcl.leaves.balance.resource,
    },
    children: [
      {
        path: SUMMARY,
        component: lazy(
          () =>
            import("@pages/Reports/AllReports/Leave/LeaveBalanceReport/Tab/BalanceReportSummary/BalanceReportSummary")
        ),
        meta: {
          action: reportAcl.leaves.balance.action,
          resource: reportAcl.leaves.balance.resource,
        },
      },
      {
        path: STATISTICS,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Leave/LeaveBalanceReport/Tab/BalanceReportStatistics/BalanceReportStatistics"
            )
        ),
      },
    ],
  },

  // --------payroll route-----

  {
    path: REPORTS + PAYROLL + "/" + STATISTICS + "/*",
    component: lazy(() => import("@pages/Reports/AllReports/Payroll/Statistics/PayrollStatistics")),
    meta: {
      action: reportAcl.leaves.applicationStatus.action,
      resource: reportAcl.leaves.applicationStatus.resource,
    },
    children: [
      {
        path: PAYMENT,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/Statistics/Tab/PayrollPayment/PayrollPayment")),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
      {
        path: EMPLOYEE_COST,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/Statistics/Tab/EmployCost/EmployCost")),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
      {
        path: ADJUSTMENTS,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/Statistics/Tab/Adjustments/Adjustments")),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
    ],
  },
  {
    path: REPORTS + PAYROLL + "/*",
    component: lazy(() => import("@pages/Reports/AllReports/Payroll/AttendanceLeave/AttendanceLeave")),
    meta: {
      action: reportAcl.leaves.applicationStatus.action,
      resource: reportAcl.leaves.applicationStatus.resource,
    },
    children: [
      {
        path: PAYROLL_ATTENDANCE,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/AttendanceLeave/Tab/Attendance/Attendance")),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
      {
        path: PAYROLL_LEAVE,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/AttendanceLeave/Tab/Leave/Leave")),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
      {
        path: ADJUSTMENTS,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/Statistics/Tab/Adjustments/Adjustments")),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
    ],
  },

  // payroll summery

  {
    path: REPORTS + "/" + PAYROLLS + "/" + SUMMARY,
    component: lazy(() => import("@pages/Reports/AllReports/Payroll/PayrollSummary/PayrollSummary")),

    meta: {
      action: reportAcl.leaves.applicationStatus.action,
      resource: reportAcl.leaves.applicationStatus.resource,
    },
  },

  // salary sheet
  {
    path: REPORTS + "/" + PAYROLLS + SALARY_SHEET,
    component: lazy(() => import("@pages/Reports/AllReports/Payroll/SalarySheet/SalarySheet")),

    meta: reportAcl.payroll.salarySheets,
  },
  {
    path: REPORTS + "/" + PAYROLLS + SALARY_SHEET + "/" + PAYSLIPS,
    component: lazy(() => import("@pages/Reports/AllReports/Payroll/SalarySheet/Components/ViewPayslips")),

    meta: reportAcl.payroll.salarySheetSummary,
  },
  // Pay Schedules
  // {
  //   path: REPORTS + PAYROLL + "-" + PAY_SCHEDULES + "/*",
  //   component: lazy(() => import("@pages/Reports/AllReports/Payroll/PaySchedule/PaySchedules")),
  //   meta: reportAcl.payroll.paySchedules,
  //   children: [
  //     {
  //       path: SUMMARY,
  //       component: lazy(() => import("@pages/Reports/AllReports/Payroll/PaySchedule/Tab/Summary/Summary")),
  //       meta: reportAcl.payroll.paySchedules,
  //     },
  //     {
  //       path: STATISTICS,
  //       component: lazy(() => import("@pages/Reports/AllReports/Payroll/PaySchedule/Tab/Statistics/Statistics")),
  //       meta: reportAcl.payroll.paySchedules,
  //     },
  //   ],
  // },

  // ---payroll individual summery
  {
    path: REPORTS + "/" + INDIVIDUAL + "/" + PAYROLLS + "/*",
    component: lazy(() => import("@pages/Reports/AllReports/Payroll/IndividualSummary/IndividualPayrollSummary")),
    meta: reportAcl.payroll.individualSummery,
    children: [
      {
        path: SUMMARY,
        component: lazy(
          () => import("@pages/Reports/AllReports/Payroll/IndividualSummary/Tab/Summary/IndividualPayroll")
        ),

        meta: reportAcl.payroll.individualPayroll,
      },
      {
        path: STATISTICS,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/IndividualSummary/Tab/Statistics/Statistics")),

        meta: reportAcl.payroll.individualPayroll,
      },
    ],
  },
  {
    path: REPORTS + "/" + PAYROLLS + "/" + INDIVIDUAL + "/" + PAYSLIPS + "/details",
    component: lazy(
      () => import("@pages/Reports/AllReports/Payroll/IndividualSummary/Tab/Summary/Components/ReportPayslips")
    ),
    meta: reportAcl.payroll.individualPayroll,
  },

  // --------schedule route
  {
    path: REPORTS + SCHEDULES_OVERVIEW,
    component: lazy(() => import("@pages/Reports/AllReports/Schedule/SchedulesOverview/SchedulesOverview")),
    meta: {
      action: reportAcl.schedules.allEmployee.action,
      resource: reportAcl.schedules.allEmployee.resource,
    },
  },

  {
    path: `${REPORTS}/${PAYROLLS}/${PAYMENT}/*`,
    component: lazy(() => import("@pages/Reports/AllReports/Payroll/PaymentChannels/PaymentChannelReport")),
    meta: reportAcl.payroll.individualSummery,
    children: [
      {
        path: CHANNELS,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/PaymentChannels/PaymentChannels")),
        meta: reportAcl.payroll.salarySheetSummary,
      },
      {
        path: PREVIEW,
        component: lazy(() => import("@pages/Reports/AllReports/Payroll/PaymentChannels/Components/PDFPreview")),

        meta: reportAcl.payroll.salarySheetSummary,
      },
    ],
  },
];
