import { lazy } from "react";
import { IRouteList } from "./list.route";
import { DASHBOARD, MYSPACE, TRACKING, WORKSPACE } from "./path-name.route";

export const dashboardRoute: IRouteList[] = [
  {
    path: DASHBOARD + "/*",
    component: lazy(() => import("@pages/Dashboard/Dashboard")),

    children: [
      {
        path: WORKSPACE,
        component: lazy(() => import("@pages/Dashboard/Admin/Admin")),
      },
      {
        path: MYSPACE,
        component: lazy(() => import("@pages/Dashboard/Employee/Employee")),
      },
    ],
  },

  {
    path: TRACKING,
    component: lazy(() => import("@pages/Dashboard/Components/LocationTracking/LiveTracking")),
  },
];
