import { generalSettingsAcl } from "@acl/modules/setting.data";

import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
  CUSTOMER_ACCOUNTS,
  EMPLOYMENTS_SETTING,
  SUBSCRIPTIONS,
  SYSTEM_ADDRESS,
  SYSTEM_CONTACT,
  SYSTEM_INFO,
  SYSTEM_SETTINGS,
} from "./path-name.route";

const SystemSettings = lazy(() => import("@pages/Settings/SystemSettings/SystemSettings"));
const SystemContact = lazy(() => import("@pages/Settings/SystemSettings/Tabs/GeneralContact"));
const SystemAddress = lazy(() => import("@pages/Settings/SystemSettings/Tabs/SystemAddress"));
const SystemInfo = lazy(() => import("@pages/Settings/SystemSettings/Tabs/SystemInfo"));
const CompanyAccounts = lazy(() => import("@pages/Settings/SystemSettings/Tabs/CompanyAccounts"));
const EmploymentSetting = lazy(() => import("@pages/Settings/SystemSettings/Tabs/EmploymentSetting"));
const SubscriptionsSettings = lazy(() => import("@pages/Settings/Subscriptions/Subscriptions"));

export const systemRoutes: IRouteList[] = [
  {
    path: SYSTEM_SETTINGS + "/*",
    component: SystemSettings,
    meta: generalSettingsAcl.systemSettings.parent,
    children: [
      {
        path: SYSTEM_CONTACT,
        component: SystemContact,
        meta: generalSettingsAcl.systemSettings.general,
      },
      {
        path: SYSTEM_ADDRESS,
        component: SystemAddress,
        meta: generalSettingsAcl.systemSettings.address,
      },
      {
        path: SYSTEM_INFO,
        component: SystemInfo,
        meta: generalSettingsAcl.systemSettings.systemInfo,
      },
      {
        path: CUSTOMER_ACCOUNTS,
        component: CompanyAccounts,
        meta: generalSettingsAcl.systemSettings.createAccountSettings,
      },
      {
        path: EMPLOYMENTS_SETTING,
        component: EmploymentSetting,
        meta: generalSettingsAcl.systemSettings.createAccountSettings,
      },
    ],
  },
];

export const subscriptionsRoutes: IRouteList[] = [
  {
    path: SUBSCRIPTIONS,
    component: SubscriptionsSettings,
    meta: generalSettingsAcl.systemSettings.systemInfo,
  },
];
