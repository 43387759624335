import { IReportCL } from "@acl/acl.interfaces";

export const reportAcl: IReportCL = {
  parent: { action: "reports", resource: "reports" },
  employees: {
    allSummary: {
      action: "allSummary",
      resource: "reports",
    },
    individualDetails: {
      action: "individualDetails",
      resource: "reports",
    },
  },

  attendances: {
    allEmployee: {
      action: "getAttendanceReport",
      resource: "reports",
    },
    individualEmployee: {
      action: "individualAttendance",
      resource: "reports",
    },
    dailySummary: {
      action: "dailyAttendanceReport",
      resource: "reports",
    },
    allSummary: {
      action: "getAttendanceSummaryReport",
      resource: "reports",
    },
    getLateEarlyAttendances: {
      action: "getLateEarlyAttendances",
      resource: "attendances",
    },
  },

  leaves: {
    applicationStatus: {
      action: "getLeaveApplicationStatusReport",
      resource: "reports",
    },
    balance: {
      action: "getLeaveBalanceReport",
      resource: "reports",
    },
  },
  payroll: {
    // currently not used
    paySchedules: {
      action: "getReport",
      resource: "periodic-payrolls",
    },
    individualSummery: {
      action: "getReportByEmployee",
      resource: "employee-salaries",
    },

    // new
    salarySheets: {
      action: "salarySheets",
      resource: "salary-sheets",
    },

    salarySheetSummary: {
      action: "salarySheetSummary",
      resource: "salary-sheets",
    },
    individualPayroll: {
      action: "employee-salaries",
      resource: "getReportByEmployee",
    },
  },

  schedules: {
    allEmployee: {
      action: "getSchedulesReport",
      resource: "reports",
    },
  },
};
